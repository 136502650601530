import { vehicleHistoryLeadAction } from '@core/Actions/VehicleHistory/VehicleHistory.action';
import { updateReportsRequestedAtom } from '@core/Atoms/HistoryCheck/HistoryCheck.atom';
import { localeAtom } from '@core/Atoms/Locale/Locale.atom';
import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { shouldShowLoaderAtom } from '@core/Atoms/Pdp/LoadingState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { buildLeadPayload } from '@core/Utils/Leads/BuildLeadPayload';
import { postLead } from '@core/Utils/Leads/PostLead';
import { generateHovTrackingData } from '@tracking/Hov.tracking';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { atom } from 'jotai';

import { leadCtasConfig } from 'Components/Pdp/LeadCTAs/LeadCtaConfig';

import { leadFormStringsAtom } from './LeadFormStrings.atom';
import { openErrorModalAtom } from './OpenErrorModal.atom';
import { openLoadingModalAtom } from './OpenLoadingModal.atom';

import { openSuccessModalAtom } from './OpenSuccessModal.atom';

export const vehicleHistoryLeadFormSubmitAtom = atom(null, async (get, set) => {
  const locale = get(localeAtom);
  const formState = get(formStateAtom);
  const vehicle = get(currentVehicleAtom)!;
  const subtype = leadCtasConfig['history-check'].leadSubtype();
  const strings = get(leadFormStringsAtom);
  const searchParams = get(searchParamsAtom);

  const header = strings.historyCheckModalHeader;

  set(openLoadingModalAtom, { header });

  let body;
  try {
    body = buildLeadPayload({
      leadType: subtype,
      values: formState,
      vehicle: vehicle,
      subtype,
      source: 'PDP',
    });
  } catch (error) {
    console.error('Error occured when building payload', error);
    set(shouldShowLoaderAtom, false);
    return set(openErrorModalAtom, { header });
  }

  let leadResponse, userId;
  try {
    leadResponse = await postLead(locale, body);

    if (!leadResponse || !leadResponse?.lead_id) {
      set(shouldShowLoaderAtom, false);
      return set(openErrorModalAtom, { header });
    }
    const vehicleCheckLeadResponse =
      vehicle?.details?.registration &&
      (await vehicleHistoryLeadAction(vehicle?.details?.registration));

    if (!vehicleCheckLeadResponse) {
      set(shouldShowLoaderAtom, false);
      return set(openErrorModalAtom, { header });
    }

    ({ user_id: userId } = leadResponse);

    set(updateReportsRequestedAtom, vehicle.details.registration, formState.email);

    set(userIdAtom, userId);
    const {
      hovSubmit: { event, context },
    } = generateHovTrackingData({
      searchParams,
      userId,
      formState,
      vehicle,
    });

    trackCustomEvent({ event, context });

    set(shouldShowLoaderAtom, false);

    set(openSuccessModalAtom, { header: header });
  } catch (error) {
    set(shouldShowLoaderAtom, false);
    return set(openErrorModalAtom, { header });
  }
});
