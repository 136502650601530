import { useEffect } from 'react';

import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { shouldShowLoaderAtom } from '@core/Atoms/Pdp/LoadingState.atom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { useGoogleRecaptcha } from '@core/Hooks/UseGoogleRecaptcha';
import { clientLog } from '@core/Utils/Log/ClientLog';
import { Typography } from '@gds/Typography/Typography';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';
import { hasKwankoCookieApprovedAtom } from '@tracking/Atoms/HasKwankoCookieApproved.atom';
import { useAtomValue, useSetAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';

import { leadFormFieldsAtom } from 'Atoms/App/Pdp/LeadFormFields.atom';
import { leadFormIdAtom } from 'Atoms/App/Pdp/LeadFormId.atom';

import { leadFormStringsAtom } from 'Atoms/App/Pdp/LeadFormStrings.atom';
import { leadFormSubmitAttemptedAtom } from 'Atoms/App/Pdp/leadFormSubmitAttempted.atom';
import { FormFields } from 'Components/FormFields/FormFields';
import { LeadType } from 'Components/Pdp/LeadCTAs/LeadCTAs.entity';
import { LeadCtaRequiredFields, leadCtasConfig } from 'Components/Pdp/LeadCTAs/LeadCtaConfig';
import { PdPMessagesKeys } from 'Components/Pdp/Pdp.messages';
import { useFormState } from 'Hooks/UseFormState';

import { leadFormSubmitAtom } from '../../../Atoms/App/Pdp/LeadFormSubmit.atom';
import { Enrichment } from '../Enrichment/Enrichment';
import { LeadsFormFooter } from '../LeadsFormFooter/LeadsFormFooter';

import { MarketingOptInCheckbox } from '../MarketingOptInCheckbox/MarketingOptInCheckbox';
import { OneClickCheckbox } from '../OneClickCheckbox/OneClickCheckbox';
import { OneClickDriftrockCheckbox } from '../OneClickDriftrockCheckbox/OneClickDriftrockCheckbox';
import { ReCaptchaPolicy } from '../ReCaptchaPolicy/ReCaptchaPolicy';
import { ReservationCheckbox } from '../ReservationCheckbox/ReservationCheckbox';
import { ReservationCheckoutCta } from '../ReservationCheckoutCta/ReservationCheckoutCta';

import { LeadFormProps } from './LeadForm.entity';

import styles from './LeadForm.module.css';

const getCtaMessages = (strings: Record<PdPMessagesKeys, string>, leadId: LeadType) => {
  const buttonMapping = {
    'request-video': strings.requestVideoButton,
    'request-images': strings.requestImagesButton,
    'book-appointment': strings.bookAppointmentButton,
    'request-callback': strings.requestCallbackButton,
    'message-dealer': strings.messageDealerButton,
    'message-dealer-finance': strings.messageDealerButton,
    'call-dealer': strings.callDealerButton,
    'history-check': strings.historyCheckButton,
    'leasing-online': strings.leasingOnlineButton,
  };

  return buttonMapping[leadId] ?? '';
};

export const LeadForm = ({ strings, locale, plp }: LeadFormProps) => {
  useHydrateAtoms([[leadFormStringsAtom, strings]]);

  useGoogleRecaptcha(locale);

  const leadId = useAtomValue(leadFormIdAtom)!;
  const formFields = useAtomValue(leadFormFieldsAtom);
  const vehicle = useAtomValue(currentVehicleAtom)!;
  const showLoader = useSetAtom(shouldShowLoaderAtom);
  const leadFormSubmitAttempted = useSetAtom(leadFormSubmitAttemptedAtom);
  const customSubmitHandlerAtom = leadId && leadCtasConfig[leadId]?.submitHandlerAtom;
  const submitHandler = useSetAtom(customSubmitHandlerAtom || leadFormSubmitAtom);

  const flags = useAtomValue(flagsAtom);
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleKeyDown,
    inputRefs,
    handleSubmitValidation,
  } = useFormState(strings);
  const oneClickCheckboxFields = formFields.find(({ id }) => id === 'oneClickChkBox');
  const oneClickDriftrockCheckboxFields = formFields.find(
    ({ id }) => id === 'oneClickDriftrockChkBox',
  );
  const reserveVehicleCheckboxFields = formFields.find(({ id }) => id === 'reserveVehicleChkBox');
  const marketingOptInFields = formFields.find(({ id }) => id === 'marketingOptIns');

  const requiredFieldsWithoutFullName: (keyof LeadFormState)[] = ['firstName', 'lastName', 'phone'];
  const requiredFieldsWithFullName: (keyof LeadFormState)[] = ['fullName', 'phone'];

  const requiredFields = flags.nameFieldLeadformExperiment
    ? requiredFieldsWithFullName
    : requiredFieldsWithoutFullName;

  const emailRequiredFields: (keyof LeadFormState)[] = ['email', ...requiredFields];

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    leadFormSubmitAttempted(true);
    const isFormValid = handleSubmitValidation(); // TODO: swap for native validation
    const pathname = window.location.pathname;
    const isLeasingUrl = pathname.endsWith('/leasing');

    if (isFormValid) {
      showLoader(true);
      const submitType = isLeasingUrl ? 'LP' : 'PDP';
      submitHandler?.(submitType);
    } else clientLog({ data: values, label: 'Form validation failed', color: '#ffdddd' });
  };

  const formRequiredFields: LeadCtaRequiredFields =
    leadId && leadCtasConfig[leadId].requiredFields?.();

  const hasValues =
    leadId === 'request-callback'
      ? requiredFields.every(field => values?.[field]) && values?.date
      : formRequiredFields
      ? formRequiredFields.every(field => values?.[field])
      : emailRequiredFields.every(field => values?.[field]);

  const validLeadIdsForExp = ['message-dealer', 'request-callback'];
  const hasSelectedLeadIds = validLeadIdsForExp.includes(leadId);
  const hasLeasingLeadId = leadId === 'leasing-online';

  const allValuesFilled =
    hasLeasingLeadId || (locale === 'uk' ? hasValues && values?.postcode : hasValues);

  const isExperimentActive = flags.submitCtaLeadformExperiment;

  const shouldDisableButton = hasSelectedLeadIds && isExperimentActive ? true : !!allValuesFilled;

  const hasKwankoCookie = useAtomValue(hasKwankoCookieApprovedAtom);
  useEffect(() => {
    if (hasKwankoCookie && window.KWKUniJS !== undefined) {
      KWKUniJS.ptag({
        zone: 'lead_inscription',
      });
    }
  }, []);
  return (
    <form onSubmit={onSubmit} autoComplete="on" noValidate={isExperimentActive} id={leadId}>
      <div className={styles.modalBody}>
        {hasLeasingLeadId && <Typography variant="body3">{strings.leasingOnlineBody}</Typography>}
        <FormFields
          fields={formFields}
          leadType={leadId}
          strings={strings}
          fieldProps={{
            touched,
            errors,
            handleChange,
            handleBlur,
            handleKeyDown,
            inputRefs,
          }}
        />
        <hr className={styles.stroke} />
        <Enrichment
          fieldItems={formFields}
          strings={strings}
          inputProps={{ touched, errors, handleChange, handleBlur, values }}
          locale={locale}
          plp={plp}
          flags={flags}
        />
        {oneClickCheckboxFields && (
          <OneClickCheckbox
            strings={strings}
            isOneClickChecked={!!values.oneClickChkBox}
            inputProps={{
              id: oneClickCheckboxFields.id,
              handleChange,
            }}
          />
        )}
        {/* For driftrock leads */}
        {oneClickDriftrockCheckboxFields && (
          <OneClickDriftrockCheckbox
            strings={strings}
            isOneClickDriftrockChecked={!!values.oneClickDriftrockChkBox}
            inputProps={{
              id: oneClickDriftrockCheckboxFields.id,
              handleChange,
            }}
          />
        )}
        <hr className={styles.stroke} />
        {flags.leadFormReservationCta && vehicle?.pricing.package.reservation && (
          <ReservationCheckoutCta strings={strings} locale={locale} />
        )}
        {reserveVehicleCheckboxFields && vehicle?.pricing.package.reservation && (
          <ReservationCheckbox
            strings={strings}
            isReserveVehicleChecked={!!values.reserveVehicleChkBox}
            inputProps={{
              id: reserveVehicleCheckboxFields.id,
              handleChange,
            }}
          />
        )}
        {marketingOptInFields && (
          <MarketingOptInCheckbox
            strings={strings}
            isMarketingOptInChecked={
              values.oneClickDriftrockChkBox ? false : !!values.marketingOptIns!
            }
            isOneClickDriftrockChecked={!!values.oneClickDriftrockChkBox}
            inputProps={{
              id: marketingOptInFields.id,
              handleChange,
            }}
          />
        )}
        <div className={styles.captchaWrapper}>
          <ReCaptchaPolicy strings={strings} />
        </div>
        <div className={styles.modalFooter}>
          <LeadsFormFooter
            strings={{
              privacyPolicy: strings.privacyPolicy,
              vwfsPrivacyPolicy: strings.vwfsPrivacyPolicy,
              andVwfs: strings.andVwfs,
              footerButton: getCtaMessages(strings, leadId as LeadType),
              privacyPolicyCaption: strings.privacyPolicyCaption,
            }}
            isFormFilled={shouldDisableButton}
          />
        </div>
      </div>
    </form>
  );
};

LeadForm.displayName = 'LeadForm';
